"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _formik = require("formik");
var _react = _interopRequireDefault(require("react"));
var _grid = require("@rebass/grid");
var _StyledCaret = _interopRequireDefault(require("./StyledCaret"));
var _ErrorMessage = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/form/ErrorMessage"));
var _useDetailsFormOptions = require("../../hooks/useDetailsFormOptions");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _SelectInput = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/form/SelectInput"));
var __jsx = _react.default.createElement;
const BirthdateField = props => {
  const [, {
    error,
    touched
  }] = (0, _formik.useField)(props);
  const {
    dayOptions,
    monthOptions,
    yearOptions
  } = (0, _useDetailsFormOptions.useBirthdateOptions)();
  return __jsx(_react.default.Fragment, null, __jsx(Birthdate, {
    "data-error-focus": props.name
  }, __jsx(_grid.Box, {
    flex: 6
  }, __jsx(StyledSelectInput, {
    small: true,
    name: `${props.name}.day`,
    hideChoices: true,
    ToggleComponent: _StyledCaret.default,
    placeholder: "DD",
    options: dayOptions,
    id: `${props.name}.day`,
    dataCy: `${props.name}.day`,
    isSearchable: true,
    thinShadow: true
  })), __jsx(_grid.Box, {
    flex: 9
  }, __jsx(StyledSelectInput, {
    name: `${props.name}.month`,
    hideChoices: true,
    ToggleComponent: _StyledCaret.default,
    placeholder: "MM",
    options: monthOptions,
    filterFromStart: true,
    id: `${props.name}.month`,
    dataCy: `${props.name}.month`,
    isSearchable: true,
    thinShadow: true
  })), __jsx(_grid.Box, {
    flex: 6
  }, __jsx(StyledYearSelectInput, {
    small: true,
    name: `${props.name}.year`,
    hideChoices: true,
    ToggleComponent: _StyledCaret.default,
    placeholder: "YYYY",
    options: yearOptions,
    id: `${props.name}.year`,
    dataCy: `${props.name}.year`,
    isSearchable: true,
    thinShadow: true
  }))), error && touched && typeof error === 'string' && __jsx(_ErrorMessage.default, null, error));
};
const StyledSelectInput = (0, _styledComponents.default)(_SelectInput.default).withConfig({
  displayName: "BirthdateField__StyledSelectInput",
  componentId: "sc-1akip1-0"
})(["min-height:", ";boxshadow:0 0 0 1.5px var(--borderColor);@media screen and (min-width:", "){input{font-size:16px !important;}}@media screen and (max-width:374px){padding:0;> div > div:nth-child(2){display:none;}}"], ({
  theme
}) => theme.spacing['70_XLarge'], ({
  theme
}) => theme.mediaQueries.s);
const StyledYearSelectInput = (0, _styledComponents.default)(StyledSelectInput).withConfig({
  displayName: "BirthdateField__StyledYearSelectInput",
  componentId: "sc-1akip1-1"
})([""]);
const Birthdate = (0, _styledComponents.default)(_grid.Flex).withConfig({
  displayName: "BirthdateField__Birthdate",
  componentId: "sc-1akip1-2"
})(["", "{margin-right:", ";@media screen and (min-width:", "){margin-right:", ";}&:hover{box-shadow:0 0 0 1.5px var(--borderColor);}}", "{margin-right:0;}max-width:48rem;"], StyledSelectInput, ({
  theme
}) => theme.spacing['30_Small'], ({
  theme
}) => theme.mediaQueries.m, ({
  theme
}) => theme.spacing['40_Standard'], StyledYearSelectInput);
var _default = exports.default = BirthdateField;