"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useDefaultCountry = exports.useCountryOptions = exports.useBirthdateOptions = void 0;
var _eachMonthOfInterval = _interopRequireDefault(require("date-fns/eachMonthOfInterval"));
var _endOfYear = _interopRequireDefault(require("date-fns/endOfYear"));
var _format = _interopRequireDefault(require("date-fns/format"));
var _startOfYear = _interopRequireDefault(require("date-fns/startOfYear"));
var _lodash = _interopRequireDefault(require("lodash.range"));
var _react = require("react");
var _reactIntl = require("react-intl");
var _deDE = _interopRequireDefault(require("../constants/countries/de-DE.json"));
var _enGB = _interopRequireDefault(require("../constants/countries/en-GB.json"));
var _nlNL = _interopRequireDefault(require("../constants/countries/nl-NL.json"));
/* eslint-disable import/extensions */

const getDefaultCountry = (locale, countryOptions) => {
  // In the en-GB.json file, there is no key of EN so we have to check for GB.
  if (locale === 'en') {
    return countryOptions.find(country => country.value === 'GB')?.value ?? '';
  }

  // In the nl-NL.json and de-DE.json files there are keys with NL and DE so we can just use the locale here.
  return countryOptions.find(country => country.value.toLowerCase() === locale)?.value ?? '';
};
const getCountriesList = locale => {
  if (locale === 'nl') {
    return _nlNL.default;
  }
  if (locale === 'de') {
    return _deDE.default;
  }
  return _enGB.default;
};
const useCountryOptions = () => {
  const {
    locale
  } = (0, _reactIntl.useIntl)();
  return (0, _react.useMemo)(() => {
    const countries = JSON.parse(JSON.stringify(getCountriesList(locale)));
    return Object.entries(countries).map(([key, val]) => ({
      value: key,
      label: val
    }));
  }, [locale]);
};
exports.useCountryOptions = useCountryOptions;
const useDefaultCountry = () => {
  const {
    locale
  } = (0, _reactIntl.useIntl)();
  const countryOptions = useCountryOptions();
  return getDefaultCountry(locale, countryOptions);
};
exports.useDefaultCountry = useDefaultCountry;
const useBirthdateOptions = () => {
  const {
    formatDate
  } = (0, _reactIntl.useIntl)();
  const monthOptions = (0, _react.useMemo)(() => Object.values((0, _eachMonthOfInterval.default)({
    start: (0, _startOfYear.default)(new Date()),
    end: (0, _endOfYear.default)(new Date())
  })).map(date => ({
    value: (0, _format.default)(date, 'MM'),
    label: formatDate(date, {
      month: 'long'
    })
  })), [formatDate]);
  const days = (0, _react.useMemo)(() => (0, _lodash.default)(31).map(index => {
    const val = index + 1;
    return {
      value: val.toLocaleString(undefined, {
        minimumIntegerDigits: 2
      }),
      label: val
    };
  }), []);
  const now = new Date().getUTCFullYear();
  const yearOptions = (0, _react.useMemo)(() => (0, _lodash.default)(now - 100, now).map(year => ({
    value: year.toString(),
    label: year.toString()
  })), [now]);
  return {
    dayOptions: days,
    monthOptions,
    yearOptions
  };
};
exports.useBirthdateOptions = useBirthdateOptions;